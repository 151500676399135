import { Card } from '@care/react-component-lib';
import { Box, CardContent, Typography } from '@mui/material';
import { FC } from 'react';
import Image, { StaticImageData } from 'next/image';
import { theme } from '@care/material-ui-theme';

interface Props {
  className?: string;
  iconSrc: StaticImageData;
  label: string;
}

const IconTile: FC<Props> = ({ className, iconSrc, label }) => {
  return (
    <Card
      careVariant="subtle"
      sx={{
        background: theme.palette.care?.white,
        borderRadius: '16px',
        boxShadow: '0px 0px 16px rgba(0,0,0,.25)',
        display: 'inline-block',
        height: '160px',
        margin: '0 20px',
        textAlign: 'center',
        width: '160px',
      }}
      className={className}>
      <CardContent sx={{ padding: 0, marginTop: theme.spacing(2) }}>
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: {
              margin: '3px 40px 8px 40px',
            },
          }}>
          <Image
            alt="Icon Tile"
            height={80}
            layout="intrinsic"
            loading="lazy"
            src={iconSrc}
            width={80}
          />
        </Box>
        <Box>
          <Typography>{label}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default IconTile;
