import { Box } from '@mui/material';
import { FC } from 'react';
import { Carousel as ReactCarousel, CarouselProps } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ICarouselProps
  extends Partial<
    Omit<CarouselProps, 'showArrows' | 'showStatus' | 'showThumbs' | 'stopOnHover' | 'swipeable'>
  > {
  children: JSX.Element[];
  containerClassName?: string;
  shouldAutoPlay?: boolean;
  shouldShowArrows?: boolean;
}

const Carousel: FC<ICarouselProps> = ({
  containerClassName,
  children,
  interval = 4000,
  renderArrowNext,
  renderArrowPrev,
  shouldAutoPlay,
  shouldShowArrows = false,
  width,
  ...restProps
}) => {
  return (
    <Box
      className={containerClassName}
      data-testid="carousel"
      sx={{
        '& .carousel-root': {
          height: '100%',
        },
        '& .carousel-slider': {
          height: '100%',
        },
        height: '100%',
        position: 'relative',
      }}>
      <ReactCarousel
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
        autoPlay={shouldAutoPlay}
        dynamicHeight={false}
        emulateTouch
        infiniteLoop
        interval={interval}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
        showArrows={shouldShowArrows}
        showStatus={false}
        showThumbs={false}
        stopOnHover
        swipeable
        width={width}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <Box
                component="li"
                sx={{
                  background: '#444',
                  width: 10,
                  height: 10,
                  display: 'inline-block',
                  margin: '0 8px',
                  borderRadius: 10,
                  cursor: 'pointer',
                }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
                role="option"
                aria-selected="true"
              />
            );
          }
          return (
            <Box
              component="li"
              sx={{
                background: '#ddd',
                width: 10,
                height: 10,
                display: 'inline-block',
                margin: '0 8px',
                borderRadius: 10,
                cursor: 'pointer',
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="option"
              aria-selected="false"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
        {...restProps}>
        {children}
      </ReactCarousel>
    </Box>
  );
};

export default Carousel;
