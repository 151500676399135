import Cookies from 'universal-cookie';
import { Option } from '@/components/ui';
import { HOMEPAY_PARTNERS_RX_COOKIE, StateCodeMap } from '@/constants';

export const getStatesListOptions = (): Option[] =>
  Object.keys(StateCodeMap).map((key) => ({
    label: key,
    value: StateCodeMap[key as keyof typeof StateCodeMap],
  }));

export const getPartnersRXCookie = (): string => {
  const cookies = new Cookies();
  return cookies.get(HOMEPAY_PARTNERS_RX_COOKIE) || '';
};
