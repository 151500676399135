import { Box, Link, LinkProps } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import Image from 'next/image';
import { FC } from 'react';
import playButton from '../../assets/images/play-button.png';

interface Props extends LinkProps {}

const PlayButtonLink: FC<Props> = ({ children, ...restProps }) => {
  return (
    <Link
      sx={{
        color: theme.palette.care?.black,
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 'bold',
        marginTop: '24px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
      }}
      {...restProps}>
      <Box
        sx={{
          position: 'relative',
          left: '-4px !important',
          top: '-4px !important',
        }}>
        <Image height={36} width={36} src={playButton} />
      </Box>
      <Box
        component="span"
        sx={{
          marginTop: '2px !important',
        }}>
        {children}
      </Box>
    </Link>
  );
};

export default PlayButtonLink;
