import { SegmentControl as SegmentControlMUI } from '@care/react-component-lib';
import { Grid, useTheme } from '@mui/material';

interface SegmentControlProps {
  error?: boolean;
  onChange?: (value: unknown) => void;
  options?: SegmentControlOptionsProps[];
  className?: string;
}

export interface SegmentControlOptionsProps {
  label: string;
  value: unknown;
  selected?: boolean;
  disabled?: boolean;
}

const SegmentControl = (props: SegmentControlProps) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={12}>
        <SegmentControlMUI
          sx={{
            fontFamily: theme.typography.fontFamily,
            border: `${theme.spacing(1)} solid ${theme.palette.grey[100]}`,
            borderRadius: theme.spacing(7),
            '& > span': {
              padding: theme.spacing(1),
              borderRadius: theme.spacing(5),
              [theme.breakpoints.down('sm')]: {
                fontSize: '15px !important',
              },
            },
            '& #segment-indicator': {
              padding: theme.spacing(1),
              borderRadius: theme.spacing(5),
            },
          }}
          {...props}
        />
      </Grid>
    </Grid>
  );
};

export default SegmentControl;
