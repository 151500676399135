import { FormControl, InputLabel, Select, SelectProps } from '@mui/material';
import { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react';
import { Option } from '.';

interface Props extends Omit<SelectProps, 'label'> {
  label: string;
  options: Option[];
}

const SelectBox: ForwardRefExoticComponent<Omit<Props, 'ref'> & RefAttributes<unknown>> =
  forwardRef<unknown, Props>(({ fullWidth, label, options, ...restProps }, ref) => {
    const renderOptions = (item: Option) => (
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    );

    return (
      <FormControl
        sx={{
          '& .MuiInput-root': {
            borderRadius: '3px',
          },
          width: '100%',
        }}
        fullWidth={fullWidth}
        variant="standard">
        <InputLabel id={`select-box-label-${label}`} htmlFor={restProps.id}>
          {label}
        </InputLabel>
        <Select {...restProps} inputRef={ref} labelId={`select-box-label-${label}`} native>
          {options.map(renderOptions)}
        </Select>
      </FormControl>
    );
  });

export default SelectBox;
