import { TextField, TextFieldProps } from '@care/react-component-lib';
import { InputAdornment } from '@mui/material';
import { forwardRef } from 'react';

interface Props extends Omit<TextFieldProps, 'ref'> {
  prefix?: string;
}

const InputField = forwardRef<HTMLInputElement, Props>(
  ({ onChange, prefix, ...restProps }, ref) => {
    return (
      <TextField
        InputProps={{
          startAdornment: prefix && (
            <InputAdornment
              sx={{
                marginLeft: '8px',
              }}
              position="start">
              {prefix}
            </InputAdornment>
          ),
        }}
        inputRef={ref}
        onChange={onChange}
        {...restProps}
      />
    );
  }
);

export default InputField;
