import { Box, Typography } from '@mui/material';
import Image, { ImageProps, StaticImageData } from 'next/image';
import { FC } from 'react';

interface Props {
  containerClassName?: string;
  label: string;
  labelClassName?: string;
  src: StaticImageData;
}

const LabeledImage: FC<Props & ImageProps> = ({
  alt,
  containerClassName,
  label,
  labelClassName,
  loading,
  src,
  ...restProps
}) => (
  <Box className={containerClassName} textAlign="center">
    <Box
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <Image
        alt={alt}
        aria-label={restProps['aria-label']}
        height="100%"
        loading={loading}
        src={src}
        width="100%"
      />
    </Box>
    <Box lineHeight={1.428571}>
      <Typography variant="body3">{label}</Typography>
    </Box>
  </Box>
);

export default LabeledImage;
